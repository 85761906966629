import { GigaUserApi, GigaUserApiClient } from "@giga-user-fern/api";
import {
	Background,
	Cover,
	CoverOption,
	CoverType,
	GetPronDictRequest,
	GetUsageRequest,
	HelpCenterConfig,
	HostingDetails,
	InitResponse,
	Language,
	PronDictEntryRequest,
	User,
	UserInOrg,
	UserRole,
	VideoFormat,
} from "@giga-user-fern/api/types/api";
import {
	AutoUpdateGuideRequest,
	ConvertToGifResponse,
	FileFormat,
	FinishCreateGuideRequest,
	FinishUploadSourceRequest,
	GifSettings,
	GuideRequest,
	MoveGuideRequest,
	OriginalEdits,
	ReorderGuideRequest,
	ValidateGuideUrlRequest,
} from "@giga-user-fern/api/types/api/resources/guides";
import {
	cleanJSON,
	insertImageMetadata,
} from "../components/formats/RichText/utils/cleanImageSrc";
import {
	getDataUris,
	getHTMLandTextWithImages,
} from "../components/formats/RichText/utils/exportProcessing";
import { Collection, CollectionInput } from "../core/types/collections";
import {
	Guide,
	GuideData,
	GuidePreview,
	Organization,
	Voice,
} from "../core/types/guide";
import { setBranding } from "../core/utils/styleUtils";
import { rootCollection } from "../types/files";
import logger from "../utils/logger";
import { fetcher } from "./Adapter";

import _saverconfig from "./_saverconfig";

import { ValidateCollectionUrlPathRequest } from "@giga-user-fern/api/types/api/resources/collections";
import {
	CustomDomainSetupRequest,
	UpdateOrValidateDnsRecordRequest,
} from "@giga-user-fern/api/types/api/resources/helpcenter";
import {
	AddUserToOrganizationRequest,
	BasePlanDetails,
	BasePlanStatus,
	ChangeOpenDoorPolicyRequest,
	ChangeRoleForUserRequest,
	CheckoutRequest,
	CreateOrganizationRequest,
	JoinOrganizationRequest,
	RemoveUserFromOrgRequest,
	UsageMetricsRequest,
	UtilizationRequest,
} from "@giga-user-fern/api/types/api/resources/organizations";
import { Supplier } from "@giga-user-fern/api/types/core";

const Id = GigaUserApi.Id;

const main_url = _saverconfig.main_url;

type PresignedUrl = any;

function dataURItoBlob(dataURI: string) {
	// convert base64 to raw binary data held in a string
	// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this

	try {
		const byteString = atob(dataURI.split(",")[1]);

		// separate out the mime component
		const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		// write the bytes of the string to an ArrayBuffer
		const ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		const ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		return new Blob([ab], { type: mimeString });
	} catch (e) {
		logger.error("Error", e, dataURI);
		return dataURI;
	}
}

export const uploadToPresignedXhr = async (
	body: any,
	presignedURL: PresignedUrl,
	captureEvent: (event: any) => void,
	progressCallback?: (number: number) => void,
) => {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		const data = { ...presignedURL };

		// Append fields to formData
		for (let key in data.fields) {
			formData.append(key, data.fields[key]);
		}
		formData.append("file", body);

		const xhr = new XMLHttpRequest();

		// Track upload progress
		xhr.upload.addEventListener("progress", (event) => {
			if (event.lengthComputable) {
				if (progressCallback) {
					progressCallback((event.loaded / event.total) * 100);
				}
			}
		});

		// Handle the response
		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				// Done
				if (xhr.status === 200 || xhr.status === 204) {
					resolve(true);
				} else {
					console.error("Upload failed:", xhr.status, xhr.responseText);
					captureEvent({
						eventName: "UploadError",
						value: { error: xhr.responseText },
					});
					reject(null);
				}
			}
		};

		// Handle network errors
		xhr.onerror = () => {
			console.error("Network Error");
			captureEvent({
				eventName: "UploadError",
				value: { error: "Network Error" },
			});
			reject("Network Error");
		};

		// Set up the request and send the formData
		xhr.open("POST", data.url, true);
		xhr.send(formData);
	});
};

export const uploadToPresigned = async (
	body: any,
	presignedURL: PresignedUrl,
	captureEvent: (event: any) => void,
) => {
	const formData = new FormData();
	const data = { ...presignedURL };
	// data.fields.key = presignedURL.fields.key + "hello.txt"
	for (let key in data.fields) {
		formData.append(key, data.fields[key]);
	}
	formData.append("file", body);
	try {
		const resp = await fetch(data.url, { method: "POST", body: formData });
		logger.debug("UPLOADED", resp);
		if (!resp.ok) {
			// No upload
			captureEvent({
				eventName: "UploadError",
				value: { error: JSON.stringify(resp) },
			});
			logger.debug("BODY OF FAILED UPLOAD", body);
			return false;
		}
		return resp;
	} catch (e: any) {
		try {
			const resp = await fetch(data.url, { method: "POST", body: formData });
			logger.debug("UPLOADED", resp);
			if (!resp.ok) {
				// No upload
				captureEvent({
					eventName: "UploadError",
					value: { error: JSON.stringify(resp) },
				});
				logger.debug("BODY OF FAILED UPLOAD", body);
				return false;
			}
			return resp;
		} catch (e: any) {
			logger.debug(e, e.status);

			try {
				captureEvent({
					eventName: "UploadError",
					value: { error: JSON.stringify(e) },
				});
			} catch (e) { }
			return Promise.resolve(null);
		}
	}
};

export class GuideSaver {
	mutationAPI: GigaUserApiClient;
	userAPI: GigaUserApiClient;
	token: Supplier<string>;
	organization: Organization | null;
	hostname: string | null;
	user: User | null;
	allUsers: InitResponse[] | null;
	languages: Language[] | null;
	voices: Voice[] | null;
	helpCenterConfig: HelpCenterConfig | null;
	basePlanStatus: BasePlanDetails | null;
	constructor() {
		this.mutationAPI = new GigaUserApiClient({
			token: "random",
			environment: main_url,
		});
		this.mutationAPI = new GigaUserApiClient({
			token: "random",
			environment: main_url,
		});
		this.userAPI = new GigaUserApiClient({
			token: "random",
			environment: main_url,
		});
		this.token = "";
		this.organization = null;
		this.hostname = null;
		this.user = null;
		this.allUsers = null;
		this.languages = null;
		this.voices = null;
		this.helpCenterConfig = null;
		this.basePlanStatus = null;
	}

	setCurrentCollection = () => { };
	getBrandingColor = () => {
		return this.organization?.brandColor;
	};

	getOrganization = () => {
		return this.organization;
	};

	getTokenPlatform = () => {
		//@ts-expect-error
		return this.token();
	};

	handleErrors = (resp: any) => {
		console.error("Error occured", resp);
	};

	captureEvent = (event: any) => {
		console.log("No Capture Event");
	};

	captureEventChrext = async (
		eventName: string,
		properties: Record<string, string | undefined>,
	) => {
		const token =
			typeof this.token === "function" ? await this.token() : await this.token;
		const finalProperties = {
			...properties,
			organization: this.organization?.id,
		};
		this.mutationAPI.posthog.captureEvent({
			eventName: eventName,
			properties: finalProperties,
		});
	};

	// setBrandingColor = (color:string)=>{
	//     // Used in Chrext to just maintain the same color
	//     if (!this.organization){
	//         this.organization = {id: Id('') ,domain:'no.com', brandColor: color, name: 'no'}
	//     }
	// }

	initialiseWithValues = (token: string, organization: Organization) => {
		this.token = token;
		this.mutationAPI = new GigaUserApiClient({
			//auth header goes here.
			token: token,
			//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
			//function to make sure that this token is valid.
			environment: main_url,
			fetcher: fetcher,
		});
		this.organization = organization;
		this.mutationAPI = new GigaUserApiClient({
			token: organization.id,
			environment: main_url,
			fetcher: fetcher,
		});
	};

	initializeExtension: (
		accessTokenSupplier: () => Promise<string>,
	) => Promise<Organization> = (accessTokenSupplier: () => Promise<string>) => {
		const supplyAccessToken = async () => {
			const token = await accessTokenSupplier();
			return token;
		};
		console.log("initing extension");
		return new Promise(async (resolve, reject) => {
			if (this.user && this.organization) {
				const res = await chrome.storage.local.get("organization_id");
				if (res === this.organization.id) {
					resolve(this.organization);
				}
			}
			this.token = supplyAccessToken;
			this.userAPI = new GigaUserApiClient({
				//auth header goes here.
				token: supplyAccessToken,
				//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
				//function to make sure that this token is valid.
				environment: main_url,
				fetcher: fetcher,
			});

			const res = await chrome.storage.local.get("organization_id");
			const orgId = res.organization_id;
			let resp: any = await this.userAPI.users.login();
			if (resp.ok) {
				try {
					if (resp.body.associatedToOrg) {
						console.log(orgId);
						resp = await this.userAPI.users.initialize({
							orgId: orgId ? Id(orgId) : undefined,
						});
						this.getMyUsers();
						this.getAllLanguages();
						const { userWithOrg, hostname } = resp.body;
						await chrome.storage.local.set({
							organization_id: userWithOrg.org.id,
						});
						this.hostname = hostname || null;
						this.organization = userWithOrg.org;
						this.mutationAPI = new GigaUserApiClient({
							token: async () => {
								const accessToken = await supplyAccessToken();
								return JSON.stringify({
									organization_id: userWithOrg.org.id,
									token: accessToken,
								});
							},
							environment: main_url,
							fetcher: fetcher,
						});
						this.hostname = hostname || null;
						this.user = {
							...userWithOrg.user,
							role: userWithOrg.role as UserRole,
							organizationId: userWithOrg.org.id,
						};
						console.log("resolving late, org was found", this.organization);
						//@ts-expect-error
						resolve(this.organization);
					} else {
						reject(
							new Error("User exists but is not associated to an organization"),
						);
					}
				} catch (e) {
					reject(null);
				}
			} else {
				reject(null);
			}
		});
	};

	initializePlatform: (
		accessToken: () => Promise<string>,
	) => Promise<Organization> = (accessToken: () => Promise<string>) => {
		return new Promise(async (resolve, reject) => {
			if (this.user && this.organization) {
				resolve(this.organization);
			}
			if (accessToken) {
				this.token = accessToken;
				this.userAPI = new GigaUserApiClient({
					//auth header goes here.
					token: accessToken,
					//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
					//function to make sure that this token is valid.
					environment: main_url,
					fetcher: fetcher,
				});
				const orgId = localStorage.getItem("organization_id");
				let resp;

				resp = await this.userAPI.users.login();
				if (resp.ok) {
					if (resp.body.associatedToOrg) {
						console.log(orgId, "this is the org Id");
						resp = await this.userAPI.users.initialize({
							orgId: orgId ? Id(orgId) : undefined,
						});
						console.log("Resp", resp);
						if (resp.ok) {
							this.initializePlatformWithResp(resp.body);
							await this.getBasePlanDetails();
							this.getMyUsers();
							this.getAllLanguages();
							this.getVoices();
						} else if (resp.error) {
							localStorage.removeItem("organization_id");
							const res = await this.initializePlatform(accessToken);
							resolve(res);
						}
					} else {
						reject({ userExists: true, associatedToOrg: false });
					}
				} else {
					reject(null);
				}
				if (resp.ok) {
					//@ts-expect-error
					resolve(this.organization);
				} else {
					reject(null);
				}
			}
		});
	};

	initializePlatformWithResp = (initResp: InitResponse) => {
		const { userWithOrg, hostname } = initResp;
		localStorage.setItem("organization_id", userWithOrg.org.id);
		this.organization = userWithOrg.org;
		this.mutationAPI = new GigaUserApiClient({
			//auth header goes here.
			token: async () => {
				//@ts-ignore
				const stringAccess = await this.token();
				return JSON.stringify({
					organization_id: userWithOrg.org.id,
					token: stringAccess,
				});
			},
			//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
			//function to make sure that this token is valid.
			environment: main_url,
			fetcher: fetcher,
		});
		// setBranding(this.organization.brandColor)
		this.hostname = hostname || null;
		this.user = {
			...userWithOrg.user,
			role: userWithOrg.role as UserRole,
			organizationId: userWithOrg.org.id,
		};
	};

	initialise: (accessToken: string) => Promise<Organization> = (
		accessToken: string,
	) => {
		return new Promise(async (resolve, reject) => {
			if (accessToken) {
				this.token = accessToken;
				this.mutationAPI = new GigaUserApiClient({
					//auth header goes here.
					token: accessToken,
					//Here do client side authentication and pass a token. On the backend verify the identity of the user with some other auth0
					//function to make sure that this token is valid.
					environment: main_url,
					fetcher: fetcher,
				});

				const org =
					await this.mutationAPI.organizations.organization.getOrganization();
				const host =
					await this.mutationAPI.organizations.organization.getHostname();
				if (host.ok) this.hostname = host.body;

				logger.debug("got org: ", org);

				if (org.ok) {
					this.organization = org.body;
					logger.debug("set org: ", this);
					this.mutationAPI = new GigaUserApiClient({
						token: org.body.id,
						environment: main_url,
						fetcher: fetcher,
					});
					logger.debug("branding being set", this.organization.brandColor);
					// setBranding(this.organization.brandColor)
					resolve(this.organization);
				} else {
					reject(org);
				}
			}
		});
	};

	saveAudio = async () => {
		const url = await this.mutationAPI.audio.saveAudio();
		if (url.ok) {
			return url.body;
		} else {
			return null;
		}
	};

	setDefaultVoice = async (voice: Voice) => {
		const url =
			await this.mutationAPI.organizations.organization.setDefaultVoice({
				voice,
			});
		return url.ok;
	};

	initialiseFromOrgId = async (orgId: string) => {
		/**
		 * Used in remote script
		 */

		logger.debug("initialiseFromOrgId: ", orgId);
		this.mutationAPI = new GigaUserApiClient({
			token: orgId,
			environment: main_url,
			fetcher: fetcher,
		});

		const organization =
			await this.mutationAPI.organizations.organization.getOrganizationFromId({
				id: Id(orgId),
			});

		const hostname =
			await this.mutationAPI.organizations.organization.getHostnameFromId({
				id: Id(orgId),
			});

		logger.debug("hostname: ", hostname);

		if (organization.ok) {
			this.organization = organization.body;
			setBranding(this.organization.brandColor);

			if (hostname.ok) {
				this.hostname = hostname.body;
			}

			return this.organization;
		} else {
			logger.error("failed", organization);
			return null;
		}
	};

	initialiseFromHostname = async (hostname: string) => {
		this.mutationAPI = new GigaUserApiClient({
			token: "ignore",
			environment: main_url,
			fetcher: fetcher,
		});

		const organization =
			await this.mutationAPI.organizations.organization.getOrganizationFromHostName(
				{
					hostname: hostname,
				},
			);

		if (organization.ok) {
			this.organization = organization.body;
			setBranding(this.organization.brandColor);
			this.mutationAPI = new GigaUserApiClient({
				token: this.organization.id,
				environment: main_url,
				fetcher: fetcher,
			});
			return this.organization.id;
		} else {
			logger.error("failed", organization);
			return null;
		}
	};

	// checked ✅
	fetchAllGuidePreviews = async (onlyPublished = true, getRecent = false) => {
		const allGuides = getRecent
			? await this.mutationAPI.guides.guideQueries.getRecentGuidePreviews()
			: await this.mutationAPI.guides.guideQueries.getAllGuidePreviews();

		if (allGuides.ok) {
			const guidePreviews = allGuides.body;

			if (onlyPublished) {
				return guidePreviews.filter((i) => i.header.published == true);
			} else {
				return guidePreviews;
			}
		} else {
			return [];
		}
	};

	fetchHelpCenterConfig = async () => {
		const resp = await this.mutationAPI.admin.getHelpCenterConfig();
		this.handleErrors(resp);

		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	setHelpCenterConfig = async (helpCenterConfig?: HelpCenterConfig) => {
		const resp =
			await this.mutationAPI.admin.setHelpCenterConfig(helpCenterConfig);
		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	setLogo = async (logo: string) => {
		const resp = await this.mutationAPI.admin.setLogo(logo);
		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	setFavicon = async (favicon: string) => {
		const resp = await this.mutationAPI.admin.setFavicon(favicon);
		if (resp.ok) {
			return resp.body;
		} else {
			return false;
		}
	};
	fetchAllCollections = async () => {
		const allCollections =
			await this.mutationAPI.collections.collectionQueries.getAllCollections();

		if (allCollections.ok) {
			const collections = allCollections.body;

			return collections;
		} else {
			return [];
		}
	};

	fetchAllChildren = async (
		_parentId?: string,
		onlyPublished: boolean = true,
	) => {
		var parentId = undefined;

		if (_parentId == "Collection_root") {
			parentId = undefined;
		} else if (_parentId) {
			parentId = GigaUserApi.Id(_parentId);
		}

		const allChildren =
			await this.mutationAPI.collections.collectionQueries.getAllChildren({
				parentId: parentId,
			});

		logger.debug("allChildren: ", allChildren);
		if (allChildren.ok) {
			const { guides, collections } = allChildren.body;
			if (onlyPublished) {
				const _guides = guides.filter((g) => g.header.published);
				return {
					guides: _guides,
					collections: collections,
				};
			} else {
				return allChildren.body;
			}
		} else {
			return {
				guides: [],
				collections: [],
			};
		}
	};

	getPath = async (id: string) => {
		logger.debug("saver getPath: ", id);

		const res = await this.mutationAPI.collections.collectionQueries.getPath({
			id: GigaUserApi.Id(id),
		});

		if (res.ok) {
			var path = res.body.collections;
			const full_path = [rootCollection, ...path];

			logger.debug("got path: ", full_path);

			return full_path as [Collection, ...Collection[]];
		} else {
			return false;
		}
	};

	uploadVideoChunk = async (
		chunkNumber: number,
		guideId: GigaUserApi.Id,
		data: unknown,
		formatString: VideoFormat,
		autoUpdate: GigaUserApi.Id | undefined,
	) => {
		const uploadChunkURL =
			await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
				guideId: guideId,
				chunkNumber: chunkNumber,
				format: formatString,
				updateId: autoUpdate,
			});
		if (uploadChunkURL.ok) {
			const result = await uploadToPresigned(
				data,
				uploadChunkURL.body,
				this.captureEvent,
			);
			if (!result) {
				const uploadChunkURL =
					await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
						guideId: guideId,
						chunkNumber: chunkNumber,
						format: formatString,
						updateId: autoUpdate,
					});
				if (uploadChunkURL.ok) {
					const result = await uploadToPresigned(
						data,
						uploadChunkURL.body,
						this.captureEvent,
					);
					return result;
				}
			}
			return result;
		} else {
			const uploadChunkURL =
				await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
					guideId: guideId,
					chunkNumber: chunkNumber,
					format: formatString,
					updateId: autoUpdate,
				});
			if (uploadChunkURL.ok) {
				const result = await uploadToPresigned(
					data,
					uploadChunkURL.body,
					this.captureEvent,
				);
				if (!result) {
					const uploadChunkURL =
						await this.mutationAPI.guides.guideMutations.uploadVideoChunk({
							guideId: guideId,
							chunkNumber: chunkNumber,
							format: formatString,
							updateId: autoUpdate,
						});
					if (uploadChunkURL.ok) {
						const result = await uploadToPresigned(
							data,
							uploadChunkURL.body,
							this.captureEvent,
						);
						return result;
					}
				}
				return result;
			}
		}
		return false;
	};

	uploadImage = async (guideId: GigaUserApi.Id, data: string) => {
		const uploadChunkURL =
			await this.mutationAPI.guides.guideMutations.uploadImage(guideId);
		if (uploadChunkURL.ok) {
			const result = await uploadToPresigned(
				dataURItoBlob(data),
				uploadChunkURL.body,
				this.captureEvent,
			);
			if (result) {
				return (uploadChunkURL.body as any).fields.key.split("/").at(-1);
			}
			return (uploadChunkURL.body as any).fields.key.split("/").at(-1);
		}
		return false;
	};

	compileVideo = async (
		totalChunks: number,
		guideId: GigaUserApi.Id,
		formatString: VideoFormat,
		autoUpdate: GigaUserApi.Id | undefined,
		onlyCheck: boolean = false,
	) => {
		const compiledVideo =
			await this.mutationAPI.guides.guideMutations.compileVideo({
				totalChunks: totalChunks,
				guideId: guideId,
				format: formatString,
				updateId: autoUpdate,
				onlyCheck: onlyCheck,
			});
		if (compiledVideo.ok) {
			return compiledVideo.body;
		} else {
			return false;
		}
	};

	search = async (text: string) => {
		const searchResults = await this.mutationAPI.guides.guideQueries.search({
			text: text,
		});
		if (searchResults.ok) {
			return searchResults.body;
		} else {
			return [];
		}
	};
	// #region -- GUIDES --
	guides = {
		data: {
			get: async (id: GigaUserApi.Id, version_number?: number) => {
				const rootGuides =
					await this.mutationAPI.guides.guideQueries.getGuideData({
						id: id,
						version: version_number,
					});

				if (rootGuides.ok) {
					const guideData = rootGuides.body;
					// if (guideData.video.generated?.subtitles){
					//     const blob = new Blob([guideData.video.generated?.subtitles], { type: 'text/vtt' });
					//     guideData.video.generated.subtitles = URL.createObjectURL(blob);
					// }

					return guideData;
				} else {
					return null;
				}
			},
		},
		preview: {
			get: async (id: GigaUserApi.Id) => {
				const res = await this.mutationAPI.guides.guideQueries.getGuidePreview({
					id: id,
				});

				if (res.ok) {
					const guidePreview = res.body;
					console.log(
						"guide prev log",
						guidePreview,
						typeof guidePreview.createdAt,
					);
					return guidePreview;
				} else {
					return null;
				}
			},
			update: async (_guidePreview: GuidePreview) => {
				/**
				 * Used to update only the Guide Preview.
				 * (description, name, published)
				 * Do not use this to move or reorder guides.
				 */

				var guidePreview = { ..._guidePreview };

				const res =
					await this.mutationAPI.guides.guideMutations.updateGuidePreview(
						guidePreview,
					);

				if (res.ok) {
					return res;
				}

				return false;
			},
		},
		update: {
			data: async (guide: Guide, incrementVersion: boolean = true) => {
				/**
				 * Used to update the entire Guide (i.e: GuidePreview + GuideData)
				 */

				const { id, guidePreview, guideData } = guide;
				const sanitizedGuide: GuideData = JSON.parse(JSON.stringify(guideData));
				sanitizedGuide.plainDoc.data = cleanJSON(sanitizedGuide.plainDoc.data);
				if (sanitizedGuide.video.generated) {
					sanitizedGuide.video.generated.transcript.data = cleanJSON(
						sanitizedGuide.video.generated.transcript.data,
					);
				}
				// const sanitizedGuide: GuideData = {
				//     plainDoc: {version: "2023-03-12", data:cleanJSON(richTextData)},
				//     video: {
				//         ...guideData.video
				//     },
				// };
				const updateData =
					await this.mutationAPI.guides.guideMutations.updateGuideData({
						id: id,
						guidePreview: guidePreview,
						guideData: sanitizedGuide,
					});

				if (updateData.ok) {
					const presignedURLs = updateData.body.presignedUrls;
					const promiseArray: Promise<Response | null | false>[] = [];

					const sanitisedWithID: any = insertImageMetadata(
						JSON.parse(JSON.stringify(guideData.plainDoc.data)),
						presignedURLs.images!,
						(src: string, index: number) => {
							logger.debug("UPLOADING DATA URI", src);
							const imageUpload = uploadToPresigned(
								dataURItoBlob(src),
								presignedURLs.images![index],
								this.captureEvent,
							);
							promiseArray.push(imageUpload);
						},
					);
					const fullGuide: Guide = {
						...guide,
						guideData: JSON.parse(JSON.stringify(guide.guideData)),
					};
					fullGuide.guideData.plainDoc.data = sanitisedWithID;
					if (fullGuide.guideData.video.generated) {
						fullGuide.guideData.video.generated.transcript.data = cleanJSON(
							fullGuide.guideData.video.generated.transcript.data,
						);
					}

					const finishUpdate =
						await this.mutationAPI.guides.guideMutations.finishUpdateGuideData({
							guide: fullGuide,
							incrementVersion: incrementVersion,
						});

					if (finishUpdate.ok) {
						const success = await Promise.all(promiseArray);
						if (!success.find((x) => !x || x.ok === false)) {
							return finishUpdate.body;
						} else {
							return false;
						}
					}
				} else {
					return false;
				}
			},
			originalEdits: async (
				id: GigaUserApi.Id,
				originalEdits: OriginalEdits,
			) => {
				/**
				 * This is the save function to be run from the trim window.
				 */

				const res =
					await this.mutationAPI.guides.guideMutations.updateOriginalEdits({
						id: id,
						originalEdits: originalEdits,
					});
				if (res.ok) {
					return res.body;
				} else {
					return null;
				}
			},
		},
		autoUpdate: async (request: AutoUpdateGuideRequest) => {
			const id =
				await this.mutationAPI.guides.guideMutations.autoUpdateGuideVideo(
					request,
				);
			if (id.ok) {
				return id.body;
			} else {
				return null;
			}
		},
		delete: async (guideID: GigaUserApi.Id) => {
			const resp = await this.mutationAPI.guides.guideMutations.deleteGuide({
				id: guideID,
			});
			return resp.ok;
		},
		pin: async (pinned: boolean, guidePreview: GuidePreview) => {
			const res = await this.mutationAPI.guides.guideMutations.pinGuide({
				guidePreview: guidePreview,
				isPinned: pinned,
			});

			if (res.ok) return res;

			return false;
		},
		move: async (req: MoveGuideRequest) => {
			/**
			 * Use this function to move a guide to a new collection
			 */

			var res;

			try {
				res = await this.mutationAPI.guides.guideMutations.moveGuide(req);
			} catch (e) {
				console.error("could not move the item: ", e);
			}

			if (res && res.ok) {
				return res.body;
			} else {
				return false;
			}
		},
		reorder: async (req: ReorderGuideRequest) => {
			/**
			 * Used to reorder the sequence_number. SucceedingGuide is placed after precedingGuide
			 */

			const res =
				await this.mutationAPI.guides.guideMutations.reorderGuide(req);

			if (res.ok) return res;
			else return false;
		},
		version: {
			all: async (guide: Guide) => {
				const res = await this.mutationAPI.guides.guideQueries.getAllVersions({
					id: guide.id,
				});

				if (res.ok) {
					return res.body;
				} else {
					return null;
				}
			},
			restore: async (guideId: GigaUserApi.Id, version: number) => {
				const a = await this.mutationAPI.guides.guideMutations.restoreVersion({
					id: guideId,
					version: version,
				});
				if (a.ok) {
					return a.body;
				} else {
					return false;
				}
			},
		},
		create: {
			initiate: async (parentID?: GigaUserApi.Id) => {
				const id =
					await this.mutationAPI.guides.guideMutations.initiateCreateGuide({
						parentId: parentID === rootCollection.id ? undefined : parentID,
					});
				if (id.ok) {
					return id.body;
				} else {
					return null;
				}
			},
			upload: async (
				blob: Blob,
				languageId: string,
				fileFormat: FileFormat,
				parentID?: GigaUserApi.Id,
				progressCallback?: (progress: number) => void,
			) => {
				const resp =
					await this.mutationAPI.guides.guideMutations.initiateUploadCreate({
						parentId: parentID === rootCollection.id ? undefined : parentID,
						fileFormat: fileFormat,
					});
				if (resp.ok) {
					const { id, url } = resp.body;

					const status = await uploadToPresignedXhr(
						blob,
						url,
						this.captureEvent,
						progressCallback,
					);
					if (status) {
						const resp =
							await this.mutationAPI.guides.guideMutations.finishUploadCreate({
								id: id,
								languageId: languageId,
								fileFormat: fileFormat,
							});
						if (!resp.ok) {
							if (resp.error.error === "CorruptedFileError") {
								return "CorruptedFileError";
							}
						}
						return id;
					}
				}
				return null;
			},
			saveAudio: async (
				guideId: GigaUserApi.Id,
				autoUpdate: GigaUserApi.Id | undefined,
			) => {
				const id = await this.mutationAPI.audio.saveGuideAudioUpdated({
					guideId: guideId,
					updateId: autoUpdate,
				});
				if (id.ok) {
					return id.body as any;
				} else {
					return null;
				}
			},
			finish: async (request: FinishCreateGuideRequest) => {
				const id =
					await this.mutationAPI.guides.guideMutations.finishCreateGuide(
						request,
					);
				if (id.ok) {
					return id.body;
				} else {
					return null;
				}
			},
			emptyTextGuide: async (parentId?: GigaUserApi.Id) => {
				const resp =
					await this.mutationAPI.guides.guideMutations.createEmptyTextGuide({
						parentId: parentId === rootCollection.id ? undefined : parentId,
					});

				if (resp.ok) {
					return resp.body;
				} else {
					return false;
				}
			},
		},
		export: {
			video: async (guide: Guide, captions: boolean = false) => {
				/**
				 * callback :
				 *      @param status true if succeeded. false if failed.
				 *      @param url presigned_url of the exported file.
				 */

				const a = await this.mutationAPI.guides.guideExports.exportVideo({
					guideId: guide.id,
					captions
				});
				this.handleErrors(a);
				return a;
			},
			checkForSingleUseSubscription: async (guideId: GigaUserApi.Id) => {
				const res = await this.mutationAPI.organizations.subscriptions.isSingleUseAvailable(guideId)
				this.handleErrors(res)
				return res
			},
			gif: async (guide: Guide, gifSettings?: GifSettings) => {
				/**
				 * callback :
				 *      @param status true if succeeded. false if failed.
				 *      @param url presigned_url of the exported file.
				 */

				const res = await this.mutationAPI.guides.guideExports.exportGif({
					guideId: guide.id,
					gifSettings: gifSettings || {},
				});
				this.handleErrors(res);
				return res;
			},
			article: async (
				guide: Guide,
				html: string,
				markdown: string,
				onUnsubscribed?: (admins?: UserInOrg[]) => void,
			) => {
				// const {id, guidePreview, guideData} = guide
				// const richTextData : RichTextData = cleanJSON(JSON.parse(JSON.stringify(guideData.plainDoc.data)))
				// const sanitisedGuide : Guide = JSON.parse(JSON.stringify(guide))
				// sanitisedGuide.guideData.plainDoc.data = richTextData
				// if (sanitisedGuide.guideData.video.generated){
				//     sanitisedGuide.guideData.video.generated.transcript = cleanJSON(JSON.parse(JSON.stringify(sanitisedGuide.guideData.video.generated.transcript)))
				// }

				// const updateData = await this.mutationAPI.guides.guideMutations.exportGuide({guide: sanitisedGuide})

				const res = await this.mutationAPI.guides.guideExports.exportArticle({
					guideId: guide.id,
				});

				if (res.ok) {
					const imagePresignedURLs = res.body.imagePresignedUrls;
					const dataUris = await getDataUris(html);
					const promiseArray = dataUris.map((src, index) =>
						uploadToPresigned(
							dataURItoBlob(src),
							imagePresignedURLs[index],
							this.captureEvent,
						),
					);

					const success = await Promise.all(promiseArray);
					return {
						...getHTMLandTextWithImages(
							html,
							markdown,
							res.body.hostedImageUrls,
						),
						// videoOutput: updateData.body.hostedVideoUrl
					};
				} else {
					if (res.error.error == "UnsubscribedOrgError") {
						onUnsubscribed?.(res.error.content.admins);
					}
					return false;
				}
			},
		},
		convert: {
			gif: async (
				guide: Guide,
				gifSettings: GifSettings,
				callback: (
					status: boolean,
					resp: ConvertToGifResponse | undefined,
				) => void,
			) => {
				/**
				 * callback :
				 *      @param status true if succeeded. false if failed.
				 *      @param url presigned_url of the exported file.
				 */

				const res = await this.mutationAPI.guides.guideExports.convertToGif({
					guideId: guide.id,
					gifSettings: gifSettings,
				});

				if (res?.ok) {
					callback(true, res.body);
				} else {
					callback(false, undefined);
				}
			},
		},
		voiceover: {
			generate: async (guideId: GigaUserApi.Id) => {
				const a =
					await this.mutationAPI.guides.guideMutations.generateVoiceover({
						guideId: guideId,
					});
				this.handleErrors(a);
				return a;
			},
		},
		user: {
			get: async (guideId: GigaUserApi.Id) => {
				return await this.userAPI.users.getUserForGuide(guideId);
			},
		},
		autoTranslate: async (guideId: GigaUserApi.Id, language: Language) => {
			const resp =
				await this.mutationAPI.guides.aiEnhance.translateGuideAndDuplicate({
					guideId: guideId,
					destinationLanguage: language,
					sourceLanguage: Id("en"),
				});
			this.handleErrors(resp);
			return resp;
		},
		publish: async (
			guide: Guide,
			callbacks?: {
				onSuccess?: () => void;
			},
		) => {
			const a = await this.mutationAPI.guides.guideMutations.publishGuide({
				guideId: guide.id,
			});
			this.handleErrors(a);
			if (a.ok) {
				callbacks?.onSuccess?.();
			}
			return a;
		},
		duplicate: async (guideId: GigaUserApi.Id) => {
			return await this.mutationAPI.guides.guideMutations.duplicateGuide({
				guideId: guideId,
			});
		},
		platformStatus: {
			get: async (guideId: GigaUserApi.Id) => {
				return await this.mutationAPI.guides.guidePlatformStatus.getStatus({
					guideId: guideId,
				});
			},
			markErrorViewed: async (guideId: GigaUserApi.Id) =>
				this.mutationAPI.guides.guidePlatformStatus.markErrorViewed({
					guideId: guideId,
				}),
		},
		shareableLink: {
			get: async (guidePreview: GuidePreview) => {
				var isPrivate = false;
				var hasPrivateParent = false;
				var fullPath: [Collection, ...Collection[]] = [rootCollection];

				if (!guidePreview.header.published) {
					isPrivate = true;
				}

				if (
					!guidePreview.parentId ||
					guidePreview.parentId == rootCollection.id
				) {
					isPrivate = !guidePreview.header.published;
					hasPrivateParent = false;
				} else {
					const res = await saver.getPath(guidePreview.parentId);
					if (res) {
						fullPath = res;
						fullPath.map((collection) => {
							if (collection.private) {
								isPrivate = true;
								hasPrivateParent = true;
							}
						});
					}
				}

				return {
					sharingLink: `https://${this.hostname}/${isPrivate ? "share" : "guide"}/${guidePreview.id}`,
					path: fullPath,
					hasPrivateParent,
					isPrivate,
				};
			},
		},
		sources: {
			upload: {
				initiate: async (guideId: GigaUserApi.Id, mime: string) => {
					const post =
						await this.mutationAPI.guides.guideMutations.initiateUploadSource({
							guideId,
							fileType: mime,
						});

					return post;
				},
				finish: async (req: FinishUploadSourceRequest) => {
					return await this.mutationAPI.guides.guideMutations.finishUploadSource(
						req,
					);
				},
			},
		},
		enhance: {
			article: async (request: GuideRequest) => {
				const resp =
					await this.mutationAPI.guides.aiEnhance.enhanceArticleAsync(request);
				this.handleErrors(resp);
				return resp;
			},
			videoTranscript: async (request: GuideRequest) => {
				const resp =
					await this.mutationAPI.guides.aiEnhance.enhanceVideoTranscriptAsync(
						request,
					);
				this.handleErrors(resp);
				return resp;
			},
		},
	};

	collections = {
		delete: async (collectionID: GigaUserApi.Id) => {
			const resp =
				await this.mutationAPI.collections.collectionMutations.deleteCollection(
					{
						id: collectionID,
					},
				);
			return resp.ok;
		},
		update: async (_collection: Collection) => {
			/**
			 * Used to update the Collection. NOT to be used for moving or reordering!
			 * (description, name, published)
			 */

			const collection = { ..._collection };

			const res =
				await this.mutationAPI.collections.collectionMutations.updateCollection(
					collection,
				);

			if (res.ok) {
				return res;
			} else {
				return false;
			}
		},
		visibility: {
			set: async (_collection: Collection, isPrivate: boolean) => {
				const collection = { ..._collection };

				const res =
					await this.mutationAPI.collections.collectionMutations.setCollectionVisibility(
						{
							collection: collection,
							private: isPrivate,
						},
					);

				if (res.ok) {
					return res;
				} else {
					return false;
				}
			},
		},
		move: async (collection: Collection, newParentId?: GigaUserApi.Id) => {
			/**
			 * Use this function to move a guide to a new collection
			 */

			const res =
				await this.mutationAPI.collections.collectionMutations.moveCollection({
					collection: collection,
					newParentId: newParentId,
				});

			if (res.ok) {
				return res.body;
			} else {
				return false;
			}
		},
		reorder: async (
			succeedingCollection: Collection,
			precedingCollection?: Collection,
		) => {
			/**
			 * Used to reorder the sequence_number. SucceedingGuide is placed after precedingGuide
			 */

			const res =
				await this.mutationAPI.collections.collectionMutations.reorderCollection(
					{
						succeedingCollection,
						precedingCollection,
					},
				);

			logger.debug("res: ", res);

			if (res.ok) return res;
			else return false;
		},
		create: async (collection: CollectionInput) => {
			try {
				logger.debug("collectioninput: ", collection);
				const resp =
					await this.mutationAPI.collections.collectionMutations.createCollection(
						collection,
					);
				if (resp?.ok) {
					logger.debug("created collection: ", resp.body);
					return resp.body;
				} else {
					logger.error("could not create collection: ", resp);
					return false;
				}
			} catch (e) {
				logger.error("could not create collection: ", e);
				return false;
			}
		},
	};

	getEventData = async () => {
		return await this.mutationAPI.analytics.getEventData();
	};

	getIdentity = async () => {
		return await this.mutationAPI.analytics.getIdentity();
	};

	getMyUsers = async () => {
		if (this.allUsers) {
			return this.allUsers;
		}
		const res = await this.mutationAPI.users.getUsers();
		if (res.ok) {
			this.allUsers = res.body;
			return res.body;
		} else {
			return [];
		}
	};

	getAllLanguages = async () => {
		if (this.languages) {
			return this.languages;
		}
		const res =
			await this.mutationAPI.organizations.organization.getAllLanguages();
		if (res.ok) {
			this.languages = res.body.languages;
			return res.body.languages;
		} else return [];
	};

	getAllLanguagesChrext = async () => {
		if (this.languages) {
			return this.languages;
		}
		const res =
			await this.mutationAPI.organizations.organization.getAllLanguagesChrext();
		if (res.ok) {
			this.languages = res.body.languages;
			return res.body.languages;
		} else return [];
	};

	getLanguage = async (languageId: string) => {
		const allLanguages = await this.getAllLanguages();
		const language = allLanguages.find((l) => l.languageId == languageId);
		return language;
	};

	getVoices = async () => {
		if (this.voices) {
			return this.voices;
		}

		const res = await this.mutationAPI.organizations.organization.getVoices();

		if (res.ok) {
			this.voices = res.body.voices;
			return res.body.voices;
		} else return [];
	};

	getOrgUsers = async () => {
		const res = await this.mutationAPI.admin.getUsers();
		if (res.ok) {
			return res.body;
		} else {
			return [];
		}
	};

	createUser = async (user: User) => {
		const res = await this.mutationAPI.admin.createUser(user);
		return res;
	};

	deleteUser = async (email: string) => {
		const res = await this.mutationAPI.admin.deleteUser(email);
		if (res.ok) {
			return res.body;
		} else {
			return false;
		}
	};

	getHostingDetails = async () => this.mutationAPI.admin.getHostingDetails();
	setHostingDetails = async (x: HostingDetails) =>
		this.mutationAPI.admin.setHostingDetails(x);

	// #region -- TEMPLATES --

	getPlatformDetails = async () =>
		this.mutationAPI.organizations.organization.getPlatformDetails();

	uploadBackgroundAudio = async (file: any, description: string) => {
		const track = await this.mutationAPI.audio.initiateBackgroundAudioUpload();
		if (track.ok) {
			const resp = await uploadToPresigned(
				file,
				track.body.url,
				this.captureEvent,
			);
			if (resp) {
				const trackId =
					await this.mutationAPI.audio.finishCreateBackgroundAudioUpload({
						src: track.body.src,
						description: description,
					});
				if (trackId.ok) {
					return { src: track.body.src, description: description };
				}
			}
		}
		return null;
	};

	uploadLogo = async (file: any) => {
		const logo = await this.mutationAPI.templates.initiateLogoUpload();
		if (logo.ok) {
			const { id, src, url } = logo.body;
			const resp = await uploadToPresigned(
				file,
				logo.body.url,
				this.captureEvent,
			);
			if (resp) {
				const finishResp = await this.mutationAPI.templates.finishLogoUpload({
					id: id,
					src: logo.body.src,
				});
				if (finishResp.ok) {
					return { id: id, src: src };
				}
			}
		}
	};

	uploadAsset = async () => {
		const post = await this.mutationAPI.templates.initiateAssetUpload();
		return post;
	};

	createCover = async (type: CoverType, cover: Cover) => {
		return await this.mutationAPI.templates.createCover({
			cover: cover,
			type: type,
		});
	};

	createBackground = async (background: Background) => {
		return await this.mutationAPI.templates.createBackground({
			background,
		});
	};

	deleteCover = async (type: CoverType, id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.deleteCover({
			type,
			id,
		});

		if (resp && resp.ok) return true;
		return false;
	};

	deleteBackground = async (id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.deleteBackground({
			id,
		});

		if (resp && resp.ok) return true;
		return false;
	};

	updateCover = async (type: CoverType, coverOption: CoverOption) => {
		return await this.mutationAPI.templates.updateCover({
			type,
			coverOption,
		});
	};

	setDefaultCover = async (type: CoverType, id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.setDefaultCover({
			type,
			id,
		});

		if (resp && resp.ok) return resp.body;
		return false;
	};

	setDefaultBackground = async (id: GigaUserApi.Id) => {
		const resp = await this.mutationAPI.templates.setDefaultBackground({
			id,
		});
		if (resp && resp.ok) return resp.body;
		return false;
	};

	// #endregion --- TEMPLATES ---

	getUsageVideo = async (req: GetUsageRequest) => {
		return await this.mutationAPI.admin.getUsageVideo(req);
	};

	getUsageArticle = async (req: GetUsageRequest) => {
		return await this.mutationAPI.admin.getUsageArticle(req);
	};

	validateGuideUrlPath = async (req: ValidateGuideUrlRequest) => {
		return await this.mutationAPI.guides.guideMutations.validateGuideUrlPath(
			req,
		);
	};

	validateCollectionUrlPath = async (req: ValidateCollectionUrlPathRequest) => {
		return await this.mutationAPI.collections.collectionQueries.validateCollectionUrlPath(
			req,
		);
	};

	listOfEligibleOrganizations = async () => {
		return await this.userAPI.organizations.organizationMutations.listEligibleOrganizations();
	};

	createOrganization = async (req: CreateOrganizationRequest) => {
		return await this.userAPI.organizations.organizationMutations.createOrganization(
			req,
		);
	};

	joinOrganization = async (req: JoinOrganizationRequest) => {
		const resp =
			await this.userAPI.organizations.organizationMutations.joinOrganization(
				req,
			);
		this.handleErrors(resp);
		return resp;
	};

	settings = {
		profile: {
			userData: () => this.user,
		},
		manageUsers: {
			listOfOnboardedCreators: async () => {
				const resp = await this.mutationAPI.organizations.organizationMutations.listCreatorsInCurrentOrganization();
				this.handleErrors(resp)
				return resp
			},
			listOfInvitedCreators: async () => {
				const resp = await this.mutationAPI.organizations.organizationMutations.listInvitedUsersInCurrentOrganization();
				this.handleErrors(resp)
				return resp
			},
			organizationDetails: () => this.organization,
			removeUserFromOrganization: async (req: RemoveUserFromOrgRequest) => {
				const resp = await this.mutationAPI.organizations.organizationMutations.removeUserFromOrganization(
					req,
				);
				this.handleErrors(resp)
				return resp
			},
			changeRoleOfUser: async (req: ChangeRoleForUserRequest) => {
				const resp = await this.mutationAPI.organizations.organizationMutations.changeRoleForUser(
					req,
				);
				this.handleErrors(resp)
				return resp
			},
			addUserToOrganization: async (req: AddUserToOrganizationRequest) => {
				const resp = await this.mutationAPI.organizations.organizationMutations.addUserToOrganization(
					req,
				);
				this.handleErrors(resp)
				return resp
			},
		},
		openDoorPolicy: {
			getOpenDoorPolicy: async () => {
				const resp = await this.mutationAPI.organizations.organizationMutations.getOpenDoorPolicy();
				this.handleErrors(resp)
				return resp
			},
			changeOpenDoorPolicy: async (req: ChangeOpenDoorPolicyRequest) => {
				const resp = await this.mutationAPI.organizations.organizationMutations.changeOpenDoorPolicy(
					req,
				);
				this.handleErrors(resp)
				return resp
			},
		},
		pronunciationDictionary: {
			getAllLanguages: this.getAllLanguages,
			getPronunciationDictionary: async (req: GetPronDictRequest) => {
				const resp = await this.mutationAPI.settings.fetchPronDict(req);
				this.handleErrors(resp)
				return resp
			},
			addWordToPronunciationDictionary: async (req: PronDictEntryRequest) => {
				const resp = await this.mutationAPI.settings.addPronDictEntry(req);
				this.handleErrors(resp)
				return resp
			},
			deleteWordFromPronunciationDictionary: async (
				req: PronDictEntryRequest,
			) => {
				const resp = await this.mutationAPI.settings.deletePronDictEntry(req);
				this.handleErrors(resp)
				return resp
			},
			returnPreviewAudioBytes: async (req: PronDictEntryRequest) => {
				const resp = await this.mutationAPI.settings.returnPreviewAudioBytes(req);
				this.handleErrors(resp)
				return resp
			},
		},
		manageTeam: {
			listOfOrgsCurrentUserIsAssociatedWith: async () => {
				const resp = await this.userAPI.organizations.organizationMutations.listOrganizationsUserIsIn();
				this.handleErrors(resp)
				return resp
			},
			leaveOrganization: async (req: GigaUserApi.Id) => {
				const resp = await this.mutationAPI.organizations.organizationMutations.leaveOrganization(
					req,
				)
				this.handleErrors(resp)
				return resp
			},
			listOfEligibleOrganizations: this.listOfEligibleOrganizations,
			joinOrganization: this.joinOrganization,
		},
		billingAndUsage: {
			basicUsageInCurrentCycle: async () => {
				const resp = await this.mutationAPI.organizations.usageMetrics.getBasicUsageInActivePlan()
				this.handleErrors(resp)
				return resp
			},
			subscriptionCycles: async () => {
				const resp = await this.mutationAPI.organizations.usageMetrics.getSubscriptionCycles()
				this.handleErrors(resp)
				return resp
			},
			usageMetricsForACycle: async (req: UsageMetricsRequest) => {
				const resp = await this.mutationAPI.organizations.usageMetrics.getUsageInSubscriptionCycle(req)
				this.handleErrors(resp)
				return resp
			},
			utilizationReport: async (req: UtilizationRequest) => {
				const resp = await this.mutationAPI.organizations.usageMetrics.getUtilizationInACycle(req)
				this.handleErrors(resp)
				return resp
			}
		}
	};

	getBasePlanDetails = async () => {
		if (this.basePlanStatus) {
			return this.basePlanStatus;
		}
		const resp =
			await this.mutationAPI.organizations.subscriptions.getBasePlanDetails();
		if (resp.ok) {
			this.basePlanStatus = resp.body;
			return this.basePlanStatus;
		}
		return null;
	};
	getDnsStatus = async () => {
		return await this.mutationAPI.helpcenter.helpcenterMutations.getDnsStatus();
	};

	validateDnsRecord = async (req: UpdateOrValidateDnsRecordRequest) => {
		return await this.mutationAPI.helpcenter.helpcenterMutations.validateDnsRecord(
			req,
		);
	};

	updateDnsRecord = async (req: UpdateOrValidateDnsRecordRequest) => {
		return await this.mutationAPI.helpcenter.helpcenterMutations.updateDnsRecord(
			req,
		);
	};

	setupCustomDomain = async (req: CustomDomainSetupRequest) => {
		return await this.mutationAPI.helpcenter.helpcenterMutations.customDomainSetup(
			req,
		);
	};

	verifyDnsSetup = async (req: CustomDomainSetupRequest) => {
		return await this.mutationAPI.helpcenter.helpcenterMutations.verifyDomainSetup(
			req,
		);
	};

	checkout = async (req: CheckoutRequest) => {
		const resp =
			await this.mutationAPI.organizations.subscriptions.checkout(req);
		return resp;
	};

	viewStripePortal = async () => {
		const resp =
			await this.mutationAPI.organizations.subscriptions.viewStripePortal();
		return resp;
	};

	validateCheckout = async (session: string) => {
		const resp =
			await this.mutationAPI.organizations.subscriptions.validateCheckout(
				session,
			);
		return resp;
	};
}

export const saver = new GuideSaver();
